<div style="position: relative;">
  <nav class="sidebar">
    <header>
      <p class="sidebar_header_text">Source Credit</p>
      <p class="text--ash text--capital text--center">Marketing Portal</p>
    </header>
    <div class="sidebar_menu">
      <ng-container *hasPermission="'marketing/dashboard/all'; else showAnother">
        <a class="sidebar_menu_item" [routerLink]="['/all-dashboard']" routerLinkActive="activemenu">
          <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5 16.25H12.5C13.1875 16.25 13.75 15.6875 13.75 15V5C13.75 4.3125 13.1875 3.75 12.5 3.75H5C4.3125 3.75 3.75 4.3125 3.75 5V15C3.75 15.6875 4.3125 16.25 5 16.25ZM5 26.25H12.5C13.1875 26.25 13.75 25.6875 13.75 25V20C13.75 19.3125 13.1875 18.75 12.5 18.75H5C4.3125 18.75 3.75 19.3125 3.75 20V25C3.75 25.6875 4.3125 26.25 5 26.25ZM17.5 26.25H25C25.6875 26.25 26.25 25.6875 26.25 25V15C26.25 14.3125 25.6875 13.75 25 13.75H17.5C16.8125 13.75 16.25 14.3125 16.25 15V25C16.25 25.6875 16.8125 26.25 17.5 26.25ZM16.25 5V10C16.25 10.6875 16.8125 11.25 17.5 11.25H25C25.6875 11.25 26.25 10.6875 26.25 10V5C26.25 4.3125 25.6875 3.75 25 3.75H17.5C16.8125 3.75 16.25 4.3125 16.25 5Z"
              fill="" />
          </svg>
          dashboard
        </a>
      </ng-container>
      <ng-template #showAnother>
        <a class="sidebar_menu_item" [routerLink]="['/dashboard']" routerLinkActive="activemenu">
          <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5 16.25H12.5C13.1875 16.25 13.75 15.6875 13.75 15V5C13.75 4.3125 13.1875 3.75 12.5 3.75H5C4.3125 3.75 3.75 4.3125 3.75 5V15C3.75 15.6875 4.3125 16.25 5 16.25ZM5 26.25H12.5C13.1875 26.25 13.75 25.6875 13.75 25V20C13.75 19.3125 13.1875 18.75 12.5 18.75H5C4.3125 18.75 3.75 19.3125 3.75 20V25C3.75 25.6875 4.3125 26.25 5 26.25ZM17.5 26.25H25C25.6875 26.25 26.25 25.6875 26.25 25V15C26.25 14.3125 25.6875 13.75 25 13.75H17.5C16.8125 13.75 16.25 14.3125 16.25 15V25C16.25 25.6875 16.8125 26.25 17.5 26.25ZM16.25 5V10C16.25 10.6875 16.8125 11.25 17.5 11.25H25C25.6875 11.25 26.25 10.6875 26.25 10V5C26.25 4.3125 25.6875 3.75 25 3.75H17.5C16.8125 3.75 16.25 4.3125 16.25 5Z"
              fill="" />
          </svg>
          dashboard
        </a>
      </ng-template>
      <div class="has-child">
        <div class="sidebar_menu_item pointer"
          [ngClass]="{'activemenu': reportsChild1Active.isActive || reportsChild2Active.isActive  || isActive('reports')}">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-pie-chart-fill" viewBox="0 0 16 16">
            <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"/>
          </svg>
          reports
        </div>
        <div class="submenu">
          <header class="submenu_header">
            <span class="submenu_header_text">reports</span>
            <a class="submenu_header_action" (click)="closeAllMenus()">
              <img src="assets/images/chevron-left-active.svg" width="24" height="24" alt="">
            </a>
          </header>
          <div class="submenu_menu">
            <a class="submenu_menu_item" #reportsChild1Active="routerLinkActive" routerLinkActive="activemenu"
              [routerLink]="['/sales-report']" routerLinkActive="activemenu">
              sales report
            </a>
            <a class="submenu_menu_item" #reportsChild2Active="routerLinkActive" routerLinkActive="activemenu"
              [routerLink]="['/team-report']" routerLinkActive="activemenu">
              team report
            </a>

            <ng-container *hasPermission="'sales-report'">
              <a class="submenu_menu_item" #reportsChild12Active="routerLinkActive" routerLinkActive="activemenu"
              [routerLink]="['/disbursement-report']" routerLinkActive="activemenu">
              disbursement report
            </a>

            <a class="submenu_menu_item" #reportsChild3Active="routerLinkActive" routerLinkActive="activemenu"
              [routerLink]="['/numbers-operations-report']">
              number operations report
            </a>
            <a class="submenu_menu_item" #reportsChild4Active="routerLinkActive" routerLinkActive="activemenu"
              [routerLink]="['/call-log-report']">
              call log report
            </a>
            <!-- <a class="submenu_menu_item" #reportsChild5Active="routerLinkActive" routerLinkActive="activemenu"
              [routerLink]="['/call-score']">
              call operation score
            </a> -->
            <!-- <a class="submenu_menu_item" #reportsChild6Active="routerLinkActive" routerLinkActive="activemenu"
              [routerLink]="['/workspace-report']">
              Number Operation Result
            </a> -->
            <a class="submenu_menu_item" #reportsChild7Active="routerLinkActive" routerLinkActive="activemenu"
            [routerLink]="['/no-call-log']">
           No Call Log
          </a>
          <a class="submenu_menu_item" #reportsChild8Active="routerLinkActive" routerLinkActive="activemenu"
            [routerLink]="['/no-returned-calls']">
           No Returned Call
          </a>
          <!-- <a class="submenu_menu_item" #reportsChild9Active="routerLinkActive" routerLinkActive="activemenu"
          [routerLink]="['/premium-report']">
        Premium Report
        </a> -->
        <a class="submenu_menu_item" #reportsChild10Active="routerLinkActive" routerLinkActive="activemenu"
        [routerLink]="['/disbursement-and-airtime-analysis']">
      Disbursement and Airtime Analysis
      </a>
      <!-- <a class="submenu_menu_item" #reportsChild11Active="routerLinkActive" routerLinkActive="activemenu"
        [routerLink]="['/commission-and-bonus']">
        Commission and Bonus
      </a> -->
      </ng-container>
          </div>
          
        </div>
      </div>

      <div class="has-child">
        <div class="sidebar_menu_item pointer"
          [ngClass]="{'activemenu': marketersChild1Active.isActive || marketersChild2Active.isActive || marketersChild3Active.isActive}">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
          </svg>
          marketers and team
        </div>
        <div class="submenu">
          <header class="submenu_header">
            <span class="submenu_header_text">marketers & team</span>
            <a class="submenu_header_action" (click)="closeAllMenus()">
              <img src="assets/images/chevron-left-active.svg" width="24" height="24" alt="">
            </a>
          </header>
          <div class="submenu_menu">
            <a class="submenu_menu_item" #marketersChild1Active="routerLinkActive" routerLinkActive="activemenu"
              [routerLink]="['/marketers']" routerLinkActive="activemenu">
              marketers
            </a>
            <a class="submenu_menu_item" #marketersChild2Active="routerLinkActive" routerLinkActive="activemenu"
              [routerLink]="['/marketing-team']" routerLinkActive="activemenu">
              marketing team
            </a>
            <a class="submenu_menu_item" #marketersChild3Active="routerLinkActive" routerLinkActive="activemenu"
            [routerLink]="['/request-manager']" routerLinkActive="activemenu">
            request manager
          </a>
          <a class="submenu_menu_item" #marketersChild3Active="routerLinkActive" routerLinkActive="activemenu"
          [routerLink]="['/list-out-of-office']" routerLinkActive="activemenu">
          Out of Office
        </a>
          </div>
        </div>
      </div>


      <div class="has-child">
        <div class="sidebar_menu_item pointer"
          [ngClass]="{'activemenu': workspaceChild1Active.isActive}">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-telephone-outbound-fill" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"/>
          </svg>
          workspace
        </div>
        <div class="submenu">
          <header class="submenu_header">
            <span class="submenu_header_text">workspace</span>
            <a class="submenu_header_action" (click)="closeAllMenus()">
              <img src="assets/images/chevron-left-active.svg" width="24" height="24" alt="">
            </a>
          </header>
          <div class="submenu_menu">
            <a class="submenu_menu_item" #workspaceChild1Active="routerLinkActive" routerLinkActive="activemenu"
              [routerLink]="['/workspace1']" routerLinkActive="activemenu">
              workspace
            </a>
            <!-- <a class="submenu_menu_item" #workspaceChild2Active="routerLinkActive" routerLinkActive="activemenu"
              [routerLink]="['/advance-workspace']" routerLinkActive="activemenu">
              advance-workspace
            </a> -->
          </div>
        </div>
      </div>

      <div class="has-child">
        <div class="sidebar_menu_item pointer"
          [ngClass]="{'activemenu': numbersChild1Active.isActive || numbersChild2Active.isActive}">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-telephone-plus-fill" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z"/>
          </svg>
          marketing list
        </div>
        <div class="submenu">
          <header class="submenu_header">
            <span class="submenu_header_text">marketing list</span>
            <a class="submenu_header_action" (click)="closeAllMenus()">
              <img src="assets/images/chevron-left-active.svg" width="24" height="24" alt="">
            </a>
          </header>
          <div class="submenu_menu">
            <a class="submenu_menu_item" #numbersChild1Active="routerLinkActive" routerLinkActive="activemenu"
              [routerLink]="['/number-management1']" routerLinkActive="activemenu">
              basic list
            </a>
            <a class="submenu_menu_item" #numbersChild2Active="routerLinkActive" routerLinkActive="activemenu"
              [routerLink]="['/advance-number-managment']" routerLinkActive="activemenu">
              premium list
            </a>
          </div>
        </div>
      </div>
      <div class="has-child">
        <div class="sidebar_menu_item pointer"
          [ngClass]="{'activemenu': campaignChild1Active.isActive || campaignChild2Active.isActive}">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-lightning-fill" viewBox="0 0 16 16">
            <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"/>
          </svg>
          campaigns
        </div>
        <div class="submenu">
          <header class="submenu_header">
            <span class="submenu_header_text">campaigns</span>
            <a class="submenu_header_action" (click)="closeAllMenus()">
              <img src="assets/images/chevron-left-active.svg" width="24" height="24" alt="">
            </a>
          </header>
          <div class="submenu_menu">
            <a class="submenu_menu_item" #campaignChild1Active="routerLinkActive" routerLinkActive="activemenu"
              [routerLink]="['/sms-campaign']" routerLinkActive="activemenu">
              SMS campaign
            </a>
            <a class="submenu_menu_item" #campaignChild2Active="routerLinkActive" routerLinkActive="activemenu"
              [routerLink]="['/premium-sms']" routerLinkActive="activemenu">
              premium SMS
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>

</div>
