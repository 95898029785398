// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  picUrl: 'https://docs.sourcecredit.ng/public/documents/adminprofile/',
  oldUrl: 'https://api.creditwallet.ng/Creditwallet/public/api/',
  cryptoKey: 'preinceps-finance',

  // api: 'https://api.princeps.cloud/api/v2/',
  // domain: 'princeps.cloud',
  // oAuthUrl: 'https://intranet.princeps.cloud/login?service=staff',

  domain: 'sourcecredit.ng',
   api: 'https://api.sourcecredit.ng/api/v2/',
  // api:'https://testapi.sourcecredit.ng/api/v2/',

  oAuthUrl: 'https://intranet.sourcecredit.ng/login?service=staff',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
