import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';

@Directive({
  selector: '[hasPermission]'
})
export class PermissionDirective implements OnInit, OnDestroy {
  private loggedUser: any;
  private requiredPermission: string;
  subscription!: Subscription;
  altTPl: TemplateRef<any>;

  @Input() set hasPermission(permission: string) {
    this.requiredPermission = permission;
    this.updateView();
  }

  @Input() set hasPermissionElse(view: TemplateRef<any>) {
    this.altTPl = view;
    this.updateView();
  }
  constructor(private tpl: TemplateRef<any>, private vcr: ViewContainerRef, private cdr: ChangeDetectorRef, private authservice: AuthService) { }

  ngOnInit(): void {
    const user = this.authservice.getUser();
    if (user) {
      this.vcr.clear();
      this.loggedUser = JSON.parse(user);
      this.updateView();
    }
  }

  ngOnDestroy(): void {

  }

  private updateView() {
    if (this.checkPermission()) {
      this.vcr.createEmbeddedView(this.tpl)
    } else if (this.altTPl) {
      this.vcr.createEmbeddedView(this.altTPl);
    } else {
      this.vcr.clear();
    }
    this.cdr.markForCheck();
  }

  private checkPermission(): boolean {
    if (!this.loggedUser) return false;
    const userPermissions = this.loggedUser.permissions.map(permission => permission.path) as any[];
    if (userPermissions) {
      return userPermissions.includes(this.requiredPermission);
    }
    return false;
  }
}
