<div class="login-container">
    
    <!-- Show email input and Get OTP button -->
    <form class="form-container" (ngSubmit)="onGetOtp()">
        <h4 class="page-title text--capital">Marketing Portal</h4>
      <div *ngIf="!isOtpSent">
        <!-- <label for="email" class="input-label">Email</label> -->
        <input id="email" type="email" [(ngModel)]="email" name="email" placeholder="Enter your Email" class="input-field" required />
        <button type="submit" class="btn-submit">Get OTP</button>
      </div>
  
      <!-- Show OTP input and Login button after OTP is sent -->
      <div *ngIf="isOtpSent">
        <label for="otp" class="input-label">Enter OTP</label>
        <input id="otp" type="text" [(ngModel)]="otp" name="otp" maxlength="8" minlength="8" placeholder="Enter the OTP sent to your email" class="input-field" required />
        <button type="submit" class="btn-submit">Login</button>
        <button type="button" class="btn-back" (click)="goBack()">Re-enter</button>  
    </div>
    </form>
  </div>
  