import { Injectable } from '@angular/core';
import { darkblue, light, lightblue, Theme } from 'src/app/theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private active: Theme = light;
  private availableThemes: Theme[] = [light, darkblue, lightblue];
  constructor() { }

  getAvailableThemes(): Theme[] {
    return this.availableThemes;
  }

  getActiveTheme(): Theme {
    return this.active;
  }

  isDarkTheme(): boolean {
    return this.active.name === darkblue.name;
  }

  isLightBlueTheme(): boolean {
    return this.active.name === lightblue.name;
  }

  setDarkTheme(): void {
    localStorage.setItem('cw-theme', JSON.stringify(darkblue));
    this.setActiveTheme(darkblue);
  }

  setLightBlueTheme(): void {
    localStorage.setItem('cw-theme', JSON.stringify(lightblue));
    this.setActiveTheme(lightblue);
  }

  setLightTheme(): void {
    localStorage.setItem('cw-theme', JSON.stringify(light));
    this.setActiveTheme(light);
  }

  setActiveTheme(theme: Theme): void {
    this.active = theme;

    Object.keys(this.active.properties).forEach(property => {
      document.documentElement.style.setProperty(
        property,
        this.active.properties[property]
      );
    });
  }
}
