import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, shareReplay, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  urlModule = 'marketing';
  urlModule2 = 'marketer';
  allCallLogCache$: any;
  constructor(private http: HttpClient) { }

  // dashboard
  fetchDashboard(obj) {
    return this.http.post(`${environment.api}${this.urlModule}/dashboard`, obj);
  }

  // personal dashboard
  fetchPersonalDashboard(obj) {
    return this.http.post(`${environment.api}${this.urlModule}/personal/dashboard`, obj);
  }

  fixCallBack(obj: {telephone: string, callback_date: string, type: number}) {
    return this.http.post(`${environment.api}${this.urlModule}/calback/set`, obj);
  }

  addComment(obj: {comment: string, id: string}) {
    return this.http.post(`${environment.api}${this.urlModule}/comment/add`, obj);
  }
  
  viewLoanOffer(obj) {
    return this.http.post(`${environment.api}marketing/getRemitaLoanOffer`, obj);
  }
  exportSalesReport(data){
    return this.http.post(`${environment.api}marketing/sales-report-export`,data)
  }

  transactionHistory(details) {
    return this.http.post(`${environment.api}marketing/sales-report/loan`, details);
  }

  fetchMarketerScore(authid) {
    return this.http.post(`${environment.api}marketer/score/list`, {authid});
  }

  fetchTeamReport(team_id?: string) {
    return this.http.post(`${environment.api}${this.urlModule}/team/report`, {team_id});
  }

  fetchTopMarketers() {
    return this.http.get(`${environment.api}${this.urlModule}/sales-dashboard`).pipe(shareReplay(1));
  }
  fetchPremiumReport() {
    return this.http.get(`${environment.api}sms-campaign/report/list`);
  }

  fetchMarketclass() {
    return this.http.get(`${environment.api}${this.urlModule2}/class`).pipe(shareReplay(1));

  }
  
  disbursementReport(details) { 
    return this.http.post(`${environment.api}marketing/disbursementReport`, details);
  }

  uploadNumberCSV(document) {
    return this.http.post(`${environment.api}${this.urlModule}/batch/add`, document, {
      reportProgress: true,
      observe: 'events'
    });
  }
  dataNetworkList(param) {
    return this.http.post(`${environment.api}request-manager/datanetwork/list`, param);
  }

  viewNumbers(obj) {
    return this.http.post(`${environment.api}${this.urlModule}/list`, obj);
  }

  advanceworkspacelist(obj) {
    return this.http.post(`${environment.api}advanced-work-space/list`, obj);
  }

  advanceworkspacechangestatus(obj) {
    return this.http.post(`${environment.api}advanced-work-space/change-status`, obj);
  }

  advanceworkspacechange_calbackdate(obj) {
    return this.http.post(`${environment.api}advanced-work-space/callback-date`, obj);
  }

  viewTeamCallLog(obj) {
    return this.http.post(`${environment.api}marketer/no-call-log`, obj);
  }

  viewTeamNoReturnedCallReport(obj) {
    return this.http.post(`${environment.api}marketer/no-return-call`, obj);
  }

  viewOverallTeamCallLog(obj): Observable<any> {
    return this.http.post(`${environment.api}marketing/team/no-call-log`, obj);
  }

  viewOverallTeamNoReturnedCallReport(obj) {
    return this.http.post(`${environment.api}marketing/team/no-return-call`, obj);
  }

  premiumSMSReport(obj) {
    return this.http.post(`${environment.api}marketing/premium/overview/list`, obj);
  }

  viewAdvanceNumbers(obj) {
    return this.http.post(`${environment.api}advanced-number-management/list`, obj);
  }

  updateNumberStatus(obj) {
    return this.http.post(`${environment.api}${this.urlModule}/status/change`, obj);
  }

  viewone_advance_workspace(obj) {
    return this.http.post(`${environment.api}${this.urlModule}/status/change`, obj);
  }

  viewOneMarketer(obj: {id: string}) {
    return this.http.post(`${environment.api}advanced-work-space/one`, obj);
  }

  addAdvanceComment(obj) {
    return this.http.post(`${environment.api}advanced-work-space/comment/add`, obj)
  }

  listMarketersWithCode(obj) {
    return this.http.get(`${environment.api}${this.urlModule2}/list?page_size=${obj.page_size}&search_text=${obj.search_text}`);
  }

  teamlistmember(obj) {
    return this.http.post(`${environment.api}marketing/team/members/list`, obj)
  }

  viewAdvanceNumbersOne(obj) {
    return this.http.get(`${environment.api}marketing/clean-list/list?page_size=${obj.page_size}&search_text=${obj.search_text}`, obj);
  }

  fetchMoreRecordsget(url, obj) {
    return this.http.get(`${url}&page_size=${obj.page_size}`, obj);
  }

  fetchGroup(obj) {
    return this.http.post(`${environment.api}marketing/team/members/list`, obj)
  }

  addmemberToTeam(obj) {
    return this.http.post(`${environment.api}marketing/team/members/assign`, obj)
  }
  removeTeamember(obj) {
    return this.http.post(`${environment.api}marketing/team/members/remove`, obj)
  }

  editmarketerClass(obj) {
    return this.http.post(`${environment.api}marketing/team/members/class/edit`, obj)
  }

  deleteTeam(obj) {
    return this.http.post(`${environment.api}marketing/team/delete`, obj)
  }

  makeTeamlead(obj) {
    return this.http.post(`${environment.api}marketing/team/lead/set`, obj)
  }


  cleanlistassign(obj) {
    return this.http.post(`${environment.api}marketing/clean-list/assign`, obj);
  }

  getnumberAlocation() {
    return this.http.get(`${environment.api}marketing/number/allocation/get`);
  }

  editnumberAlocation(obj) {
    return this.http.post(`${environment.api}marketing/number/allocation/update`, obj);
  }

  editnumberAlocationcron() {
    return this.http.get(`${environment.api}marketing/number/allocation/cron/status/update`);
  }

  getnumberAlocationcron() {
    return this.http.get(`${environment.api}marketing/number/allocation/cron/status/get`);
  }

  createTeam(obj) {
    return this.http.post(`${environment.api}marketing/team/add`, obj);
  }

  ReportCallLog(obj) {
    return this.http.post(`${environment.api}call-log/list`, obj);
  }

  listCallogReport(data) {
    return this.http.post(`${environment.api}advanced-work-space/report/excel`, data);
  }

   listMarketerScore(data) {
    return this.http.post(`${environment.api}marketer/score/list`, data);
  }

  listNoCallLogReport() {
    return this.http.get(`${environment.api}marketer/no-call-log/report`);
  }

  listNoReturnedCallReport(data) {
    return this.http.post(`${environment.api}marketer/no-returned-call/report`, data);
  }
  airtimeDisbursementAnalysis(data) {
    return this.http.post(`${environment.api}marketing/total-call-duration`, data);
  }

  listNNumbersOperationReport(data) {
    return this.http.post(`${environment.api}marketer/workspace/list`, data);
  }

  listAllMarketers() {
    return this.http.get(`${environment.api}${this.urlModule2}/admin/list`);
  }
  

  listSMSCampaign(data) {
    return this.http.post(`${environment.api}sms-management/list`, data);
  }

  fetchSalesReport(details) {
    return this.http.post(`${environment.api}${this.urlModule}/sales-report`, details);
  }
  fetchMarketingCommission(details) {
    return this.http.post(`${environment.api}marketing/commission/bonus`, details);
  }

  fetchMoreRecords(url, obj) {
    return this.http.post(`${url}`, obj);
  }
  createRefCode(obj) {
    return this.http.post(`${environment.api}${this.urlModule2}/add`, obj);
  }
  createOutofOffice(data){
    return this.http.post(`${environment.api}marketing/out-of-office/create`, data);
  }
  listOutofOffice(data){
    return this.http.post(`${environment.api}marketing/out-of-office/list`, data);
  }

  editRefCode(obj) {
    return this.http.post(`${environment.api}${this.urlModule2}/edit`, obj);
  }

  deleteRefCode(obj) {
    return this.http.post(`${environment.api}${this.urlModule2}/delete`, obj);
  }
  deleteOutofOffice(obj) {
    return this.http.post(`${environment.api}${this.urlModule}/out-of-office/delete`, obj);
  }



  exportDummyData() {
    return this.http.get('/assets/marketing.json');
  }

  // request
  createRequest(form) {
    return this.http.post(`${environment.api}request-manager/create`, form);
  }

  createnewAdvanceNumber(form) {
    return this.http.post(`${environment.api}advanced-number-management/create`, form);
  }

  listAllRequests(params) {
    return this.http.post(`${environment.api}request-manager/list`, params);
  }
  exportReport(params) {
    return this.http.post(`${environment.api}request-manager/processed/export`, params);
  }

  ApproveAllBy(params) {
    return this.http.post(`${environment.api}request-manager/approve/all`, params);
  }

  updateRequestStatus(params) {
    return this.http.post(`${environment.api}request-manager/change/status`, params);
  }

  viewSingleRequest(id) {
    return this.http.post(`${environment.api}request-manager/one`, {id});
  }

  grouplist(params) {
    return this.http.post(`${environment.api}marketing/team/list`, params);
  }


  movetoOutOfOffice(params) {
    return this.http.post(`${environment.api}marketer/auto-queue/clear`, params);
  }

  callUploadMarketingLeads(data) {
    return this.http.post(`${environment.api}upload/marketing/leads`, data);
  }

  
  sendOtp(data) {
    return this.http.post(`${environment.api}initiate/login`, data);
  }
  
  validateOtp(data) {
    return this.http.post(`${environment.api}final/login`, data);
  }
}
