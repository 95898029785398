import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() user: any;
  timeOfDay = new Date().getHours();
  currentDate = new Date().toDateString();

  constructor(private authservice: AuthService, private router: Router) { }

  ngOnInit(): void {
  }
  logUserOut() {
    localStorage.removeItem('princeps-staff-token'),

    this.router.navigate(['/login'])
    // this.authservice.logOut().then(() =>
    //   // location.replace(`https://sourcemfb.sharepoint.com/sites/the-hub`)
    // );
  }

  getTimeOfDay() {
    if (this.timeOfDay < 12) {
      return 'Good Morning';
    } else if (this.timeOfDay >= 12 && this.timeOfDay <= 17) {
      return 'Good Afternoon';
    } else if (this.timeOfDay >= 17 && this.timeOfDay <= 24) {
      return 'Good Evening';
    } else {
      return 'Good Day';
    }
  }
}
