<div class="layout">
  <section class="content">
    <aside>
      <app-sidebar></app-sidebar>
    </aside>
    <div class="right">
      <app-header [user]="user"></app-header>
      <main>
        <router-outlet></router-outlet>
      </main>
    </div>
  </section>
</div>

<!-- <nz-modal [(nzVisible)]="isAlertVisible" nzTitle="Call Log Upload Notification" [nzFooter]="null" [nzKeyboard]="false" [nzMaskClosable]="false" [nzClosable]="false">
  <ng-container *nzModalContent>
    <p>
      Please upload your call log now for your KPI Report. Please note that failure to adhere to this have a severe negative impact on your KPI.
    </p>
  </ng-container>
</nz-modal> -->


<nz-modal [(nzVisible)]="isVisible" [nzTitle]="null" (nzAfterClose)="resetAuthModal()" [nzFooter]="null" [nzKeyboard]="false" [nzMaskClosable]="false" [nzClosable]="false" (nzOnCancel)="closeModal()">
 <ng-container *nzModalContent>
  <form class="form_container" autocomplete="off" [formGroup]="loginForm" (ngSubmit)="handleSubmit()">
    <!-- <header class="auth_header">
      <h6 class="auth_header_title">staff login</h6>
    </header> -->
    <ng-container *ngIf="loginForm.errors">
      <span *ngIf="loginForm.errors.badRequest" class="text--danger mt-2 d-block text-center text--semi-small">
        {{loginForm.errors.badRequest}}
      </span>
      <span *ngIf="loginForm.errors.unAuthorized"
        class="text--danger mt-2 d-block text-center text--semi-small">
        {{loginForm.errors.unAuthorized}}
      </span>
    </ng-container>
    <div class="auth_form">
      <ng-container *ngIf="user">
        <ng-container *ngIf="formType === 1">
          <p class="auth_header_subtitle mb3">Enter your password to continue session</p>
          <div class="auth_form_group">
            <div class="auth_user" [ngStyle]="{'background-image': 'url(' + user?.photo_url+ ')'}" >
            </div>
          </div>
          <div class="auth_form_group">
            <input name="somethingweird" [type]="hide? 'password' : 'text'" formControlName="password" id="inputPassword" class="auth_input"
              autocomplete="new-password">
            <label for="inputPassword">password</label>
            <a class="icon_right" (click)="hide =! hide">
              <i class="bi" [class]="hide ? 'bi-eye' : 'bi-eye-slash'"></i>
            </a>
            <ng-container
              *ngIf="(formControls.password.dirty || formControls.password.touched) && formControls.password.errors">
              <span *ngIf="formControls.password.errors.required" id="password-help"
                class="text--danger mt-2 d-block text--semi-small">Please enter password</span>
              <span *ngIf="formControls.password.errors.minlength" id="password-help"
                class="text--danger mt-2 d-block">Password must be at least characters</span>
            </ng-container>
          </div>
          <div class="text--center">
            <button class="btn" [disabled]="isLoggingIn">
              <ng-container *ngIf="isLoggingIn">
                  <div class="spinner-border" aria-label="Loading" role="status">

                    </div>
              </ng-container>
              <ng-container *ngIf="!isLoggingIn">
                  login
              </ng-container>
            </button>
          </div>
          <div class="mt-3 text--center">
            <p class="form_text text--capital text--medium">Not {{user?.firstname}}&nbsp;{{user?.lastname}}? <a class="form_link" (click)="changeFormType(2)">Click here</a></p>
          </div>
        </ng-container>
        <ng-container *ngIf="formType === 2">
          <p class="auth_header_subtitle mb3">Please provide your email and password to access your account</p>
          <div class="auth_form_group">
            <input name="somethingweirder" type="email" autocomplete="new-email" formControlName="email" id="inputEmail" class="auth_input">
            <label for="inputEmail">Email address</label>
            <ng-container
              *ngIf="(formControls.email.dirty || formControls.email.touched) && formControls.email.errors">
              <span *ngIf="formControls.email.errors.required" id="email-help"
                class="text--danger mt-2 d-block text--semi-small">Email is required</span>
              <span *ngIf="formControls.email.errors.email" id="email-help" class="text--danger mt-2 d-block text--semi-small">Please
                enter valid email only</span>
            </ng-container>
          </div>
          <div class="auth_form_group">
            <input name="somethingweirdest" [type]="hide? 'password' : 'text'" formControlName="password" id="inputPassword" class="auth_input"
              autocomplete="new-password">
            <label for="inputPassword">password</label>
            <a class="icon_right" (click)="hide =! hide">
              <i class="bi" [class]="hide ? 'bi-eye' : 'bi-eye-slash'"></i>
            </a>
            <ng-container
              *ngIf="(formControls.password.dirty || formControls.password.touched) && formControls.password.errors">
              <span *ngIf="formControls.password.errors.required" id="password-help"
                class="text--danger mt-2 d-block text--semi-small">Please enter password</span>
              <span *ngIf="formControls.password.errors.minlength" id="password-help"
                class="text--danger mt-2 d-block text--semi-small">Password must be at least characters</span>
            </ng-container>
          </div>
          <div>
            <button class="btn" [disabled]="isLoggingIn">
              <ng-container *ngIf="isLoggingIn">
                  <div class="spinner-border" aria-label="Loading" role="status">
                    </div>
              </ng-container>
              <ng-container *ngIf="!isLoggingIn">
                  login
              </ng-container>
            </button>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!user">
        <p class="auth_header_subtitle mb3">Please provide your email and password to access your account</p>
        <div class="auth_form_group">
          <input type="email" formControlName="email" id="inputEmail" class="auth_input" autocomplete="email">
          <label for="inputEmail">Email address</label>
          <ng-container
            *ngIf="(formControls.email.dirty || formControls.email.touched) && formControls.email.errors">
            <span *ngIf="formControls.email.errors.required" id="email-help"
              class="text--danger mt-2 d-block text--semi-small">Email is required</span>
            <span *ngIf="formControls.email.errors.email" id="email-help" class="text--danger mt-2 d-block text--semi-small">Please
              enter valid email only</span>
          </ng-container>
        </div>
        <div class="auth_form_group">
          <input [type]="hide? 'password' : 'text'" formControlName="password" id="inputPassword" class="auth_input"
            autocomplete="current-password">
          <label for="inputPassword">password</label>
          <a class="icon_right" (click)="hide =! hide">
            <i class="bi" [class]="hide ? 'bi-eye' : 'bi-eye-slash'"></i>
          </a>
          <ng-container
            *ngIf="(formControls.password.dirty || formControls.password.touched) && formControls.password.errors">
            <span *ngIf="formControls.password.errors.required" id="password-help"
              class="text--danger mt-2 d-block text--semi-small">Please enter password</span>
            <span *ngIf="formControls.password.errors.minlength" id="password-help"
              class="text--danger mt-2 d-block text--semi-small">Password must be at least characters</span>
          </ng-container>
        </div>
        <div>
          <button class="btn" [disabled]="isLoggingIn">
            <ng-container *ngIf="isLoggingIn">
                <div class="spinner-border" aria-label="Loading" role="status">
                  </div>
            </ng-container>
            <ng-container *ngIf="!isLoggingIn">
                login
            </ng-container>
          </button>
        </div>
      </ng-container>
      <!-- <div class="mt-5">
        <p class="form_text text--regular">Forgot your password? <a class="form_link" href="">Click here</a></p>
      </div> -->
    </div>
  </form>
 </ng-container>
</nz-modal>

<!-- <div #authModal class="modal fade" tabindex="-1" id="authModal" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="text--right">
        <button type="button" (click)="resetAuthModal()" class="btn-close" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h6 class="commentmodal_header text--primary">
          Staff Login
        </h6>
        <div>
          <form [formGroup]="loginForm" (ngSubmit)="handleSubmit()">
            <ng-container *ngIf="loginForm.errors">
              <span [innerHtml]="loginForm.errors.badRequest" *ngIf="loginForm.errors.badRequest"
                class="text--danger mt-2 d-block text-center text--semi-small">
              </span>
              <span [innerHtml]="loginForm.errors.unAuthorized" *ngIf="loginForm.errors.unAuthorized"
                class="text--danger mt-2 d-block text-center text--semi-small">
              </span>
            </ng-container>
            <div class="mb-5">
              <label for="email" class="form-label text--regular">Email</label>
              <input type="email" class="text--medium form-control form-control-lg" id="email" formControlName="email">
              <ng-container
                *ngIf="(formControls.email.dirty || formControls.email.touched) && formControls.email.errors">
                <div class="text--danger my-2 text--left text--regular">Please enter a valid email</div>
              </ng-container>
            </div>
            <div class="mb-5">
              <label for="password" class="form-label text--regular">Password</label>
              <input name="password" type="password" class="text--medium form-control form-control-lg" id="password"
                formControlName="password">
              <ng-container
                *ngIf="(formControls.password.dirty || formControls.password.touched) && formControls.password.errors">
                <div class="text--danger my-2 text--left text--regular">Enter your passport</div>
              </ng-container>
            </div>
            <div class="text--right mt-5">
              <button class="button button--primary text--capital text--regular" type="submit"
                [disabled]="isLoggingIn">{{isLoggingIn ? 'please wait ...' : 'login'}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div> -->
