import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivateRouteGuard } from './core/guards/activate-route.guard';
import { LayoutComponent } from './layout/layout.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'receive-token',
    pathMatch: 'full'
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      // {
      //   path: '',
      //   redirectTo: 'all-dashboard',
      //   pathMatch: 'full'
      // },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'request-manager',
        // canActivate: [ActivateRouteGuard],
        loadChildren: () => import('./request-manager/request-manager.module').then(m => m.RequestManagerModule)
      },
      {
        path: 'all-dashboard',
        canActivate: [ActivateRouteGuard],
        data: { activateParams: { permission: 'marketing/dashboard/all', reroute: '/dashboard' } },
        loadChildren: () => import('./all-dashboard/all-dashboard.module').then(m => m.AllDashboardModule)
      },
      {
        path: 'workspace',
        loadChildren: () => import('./workspace/workspace.module').then(m => m.WorkspaceModule)
      },
      {
        path: 'workspace1',
        loadChildren: () => import('./workspace1/workspace1.module').then(m => m.Workspace1Module)
      },
      {
        path: 'number-management1',
        loadChildren: () => import('./number-management1/number-management1.module').then(m => m.NumberManagement1Module)
      },
      {
        path: 'workspace/:id',
        loadChildren: () => import('./view-number/view-number.module').then(m => m.ViewNumberModule)
      },
      {
        path: 'numbers',
        data: { activateParams: { permission: 'marketing/dashboard/all', reroute: '/workspace' } },
        loadChildren: () => import('./numbers-management/numbers-management.module').then(m => m.NumbersManagementModule)
      },
      {
        path: 'marketers',
        loadChildren: () => import('./marketers/marketers.module').then(m => m.MarketersModule)
      },
      {
        path: 'marketing-team',
        loadChildren: () => import('./marketing-group/marketing-group.module').then(m => m.MarketingGroupModule)
      },
      {
        path: 'list-out-of-office',
        loadChildren: () => import('./list-out-of-office/list-out-of-office.module').then(m => m.ListOutOfOfficeModule)
      },
      {
        path: 'my-report',
        loadChildren: () => import('./my-sales-report/my-sales-report.module').then(m => m.MySalesReportModule)
      },
      {
        path: 'sales-report',
        canActivate: [ActivateRouteGuard],
        data: { activateParams: { permission: 'sales-report', reroute: '/my-report' } },
        loadChildren: () => import('./sales-report/sales-report.module').then(m => m.SalesReportModule)
      },
      { path: 'disbursement-report', loadChildren: () => import('./disbursement-report/disbursement-report.module').then(m => m.DisbursementReportModule) },

      { path: 'advance-workspace', loadChildren: () => import('./advance-workspace/advance-workspace.module').then(m => m.AdvanceWorkspaceModule) },

      { path: 'advance-number-managment', loadChildren: () => import('./advance-number-managment/advance-number-managment.module').then(m => m.AdvanceNumberManagmentModule) },
      { path: 'call-log-report', loadChildren: () => import('./call-log-report/call-log-report.module').then(m => m.CallLogReportModule) },
      { path: 'numbers-operations-report', loadChildren: () => import('./numbers-operations-report/numbers-operations-report.module').then(m => m.NumbersOperationsReportModule) },
      {
        path: 'team-report',
        // canActivate: [ActivateRouteGuard],
        // data: {activateParams: {permission: 'marketing/dashboard/all', reroute: '/dashboard'}},
        loadChildren: () => import('./team-report/team-report.module').then(m => m.TeamReportModule)
      },
      { path: 'no-call-log', loadChildren: () => import('./reports/no-call-log-report/no-call-log-report.module').then(m => m.NoCallLogReportModule) },
      { path: 'no-returned-calls', loadChildren: () => import('./reports/no-returned-call-report/no-returned-call-report.module').then(m => m.NoReturnedCallReportModule) },
      { path: 'call-score', loadChildren: () => import('./reports/call-score-core/call-score-core.module').then(m => m.CallScoreCoreModule) },
      { path: 'workspace-report', loadChildren: () => import('./reports/workspace-report/workspace-report.module').then(m => m.WorkspaceReportModule) },
      { path: 'sms-campaign', loadChildren: () => import('./campaigns/sms-campaign/sms-campaign.module').then(m => m.SmsCampaignModule) },
      { path: 'premium-sms', loadChildren: () => import('./campaigns/premium-sms/premium-sms.module').then(m => m.PremiumSmsModule) },
      { path: 'premium-report', loadChildren: () => import('./reports/premium-report/premium-report.module').then(m => m.PremiumReportModule) },
      { path: 'disbursement-and-airtime-analysis', loadChildren: () => import('./reports/disbursement-and-airtime-analysis/disbursement-and-airtime-analysis.module').then(m => m.DisbursementAndAirtimeAnalysisModule) },
      { path: 'commission-and-bonus', loadChildren: () => import('./reports/commission-and-bonus/commission-and-bonus.module').then(m => m.CommissionAndBonusModule) },

    ]
  },
  {
    path: 'receive-token',
    loadChildren: () => import('./core/receive-token/receive-token.module').then(m => m.ReceiveTokenModule)
  },
  // { path: 'sms-phone-management', loadChildren: () => import('./campaigns/sms-telephone-management/sms-telephone-management.module').then(m => m.SmsTelephoneManagementModule) },




  // {
  //   path: 'send-token',
  //   loadChildren: () => import('./core/send-token/send-token.module').then(m => m.SendTokenModule)
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
