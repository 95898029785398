import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MainService } from '../core/services/main.service';

interface LoginResponse {
  status: string;
  message?: string;
  otp?: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})


export class LoginComponent {
  email: string = '';
  otp: string = '';
  isOtpSent: boolean = false;

  constructor(private router: Router, private service: MainService, private notification: NzNotificationService) { }

  // Simulate validating the OTP
  onGetOtp() {
    if(this.email && this.isOtpSent){
      return this.onLogin();
    }
    if (this.email) {
      const getOtpData = {
        email: this.email,
      };
      this.service.sendOtp(getOtpData).subscribe(
        (response: LoginResponse) => {
          if (response.status === 'success') {
            // console.log('OTP sent successfully:', response.otp);
            this.notification.info("Message", response.message);
            this.isOtpSent = true;
          }
        },
        (error) => {
          console.error('Error sending OTP:', error);
          this.notification.info("Message", "Error sending OTP: " + error.message);

        }
      );
    } else {
      console.log('Please enter a valid email');
      this.notification.info("Message", "Please enter a valid email");
    }
  }

  onLogin() {
    if (this.otp) {
      const loginData = {
        email: this.email,
        otp: this.otp,
      };
      this.service.validateOtp(loginData).subscribe(
        (response: any) => {
          if (response.status === 'success') {
            console.log('Login successful:', response);
            this.notification.info("Message", 'Login successful: ' + response.message);

            this.router.navigate(['/receive-token'], {
              queryParams: {
                status: response.status,
                message: "Successful",
                token: response.token
              }
            });
          } else {
            console.log('Login failed:', response);
            alert('Login failed: ' + response.message);
            this.isOtpSent = true;
            return;
          }
        },
        (error) => {
          console.error('Invalid OTP:', error);
          alert('Login failed: ' + error.message);
          this.isOtpSent = true;
          return;
        }
      );
    } else {
      console.log('Please enter the OTP');
      alert('Please enter the OTP');
      this.isOtpSent = true;
      return;
    }
  }

  goBack(): void {
    this.isOtpSent = false;
    this.router.navigate(['/login'])
  }

}
