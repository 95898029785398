import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  modalValue = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) { }

  storeToken(token) {
    return window.localStorage.setItem('princeps-staff-token', token);
  }

  getToken() {
    return window.localStorage.getItem('princeps-staff-token');
  }

  storeUser(user: any) {
    return localStorage.setItem('princeps-staff', JSON.stringify(user));
  }

  getUser() {
    return localStorage.getItem('princeps-staff') || null;
  }

  login(user) {
    return this.http.post(`${environment.api}login`, user);
  }

  isLoggedIn() {
    return !!this.getToken();
  }

  async clearStorage() {
    await window.localStorage.removeItem('token');
    await window.localStorage.removeItem('cw-user');
    await window.localStorage.clear();
  }

  logOut() {
    return this.clearStorage();
  }

  getAuthModalValue() {
    return this.modalValue.asObservable();
  }

  toggleModal(value: boolean) {
    return this.modalValue.next(value);
  }

  authenticateToken() {
    return this.http.get(`${environment.api}admin/details`).pipe(map((data: any) => data));
  }
}
