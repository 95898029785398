import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import {NzGridModule} from 'ng-zorro-antd/grid';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { PermissionDirective } from './directives/permission.directive';
import { CallTypePipe } from './pipes/call-type.pipe';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { SearchFilterPipe } from './search-filter.pipe';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';


@NgModule({
  declarations: [
    PermissionDirective,
    CallTypePipe,
    TimeAgoPipe,
    SearchFilterPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NzSelectModule,
    NzEmptyModule,
    NzProgressModule,
    FormsModule,
    ReactiveFormsModule,
    NzNotificationModule,
    NzDropDownModule,
    NzInputModule,
    NzModalModule,
    NzTabsModule,
    TimeAgoPipe,
    VirtualScrollerModule,
    NzSwitchModule,
    NzAvatarModule,
    NzPopconfirmModule,
    PermissionDirective,
    SearchFilterPipe,
    NzGridModule,
    NzDatePickerModule,
    NzMenuModule,
    NzIconModule,
    NzLayoutModule,
    NzFormModule,
    NzButtonModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule { }
