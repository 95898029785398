<header class="header">
  <div class="header_wrapper">
    <div class="d-flex align-items-center">
      <h4 class="user">
          {{getTimeOfDay()}}, <span class="user_name">{{user?.firstname}}</span>
        </h4>
    </div>
      <div class="user_action">
          <div class="header_date">
              {{currentDate}}
          </div>
          <div class="user_dropdown">
              <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
               <div style="display: flex; align-items: center;">
                  <nz-avatar nzSize="large" [nzSrc]="user?.photo_url" [nzAlt]="user?.firstname + ' ' + user?.lastname"></nz-avatar>
                  <img src="assets/images/chevron-down.svg" alt="" width="24" height="24">
               </div>
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu>
                    <!-- <li nz-menu-item class="text--capital">
                      <a href="https://sourcemfb.sharepoint.com/sites/the-hub">
                        &nbsp;go to the hub&nbsp;
                      </a>
                    </li> -->
                    
                    <li nz-menu-item class="text--capital">
                      <a href="https://staff.sourcecredit.ng/dashboard">&nbsp;go to staff portal&nbsp;</a>
                    </li>
                    <li nz-menu-item class="text--capital">
                      <a (click)="logUserOut()">&nbsp;log out&nbsp;</a>
                    </li>
                  </ul>
                </nz-dropdown-menu>
          </div>

      </div>
  </div>
</header>
